.Panel .wf-panel {
	border: none !important;
}
.FeedbackForm .wf-panel__body {
	margin-bottom: 50px;
}

.FeedbackFormContainer {
	margin: 12px;
	margin-top: -10px;
	flex-grow: 1;
	overflow: auto;
	width: 630px;
	height: auto;
	font-weight: 400;
	font-style: normal;
	font-size: 14px;
	color: #333333;
	line-height: normal;
}
@media only screen and (max-width: 768px) {
	.FeedbackFormContainer {
		width: 95%;
	}
}
[data-wf-efmya-id='feedback-text-0'] {
	width: 99%;
	resize: none;
}

.FeedbackFormContentCharactersRemaining {
	font-size: 12px;
	width: 99%;
	margin-top: 5px !important;
	display: flex;
	align-content: flex-end;
	justify-content: flex-end;
}

.FeedbackForm .wf-radio-button__label-text {
	padding-left: 0;
}

.FeedbackFormQuestion {
	display: flex;
	justify-content: flex-start;
	margin-top: 14px;
	align-items: center;
	align-content: flex-end;
	width: 100%;
	margin-bottom: 15px;
}

.FeedbackFormQuestion #allowContactRegardingFeedback {
	width: 564px;
}

.FeedbackFormQuestion .wf-radio-button {
	margin-left: 20px;
	margin-bottom: 0px;
}

.FeedbackFormQuestion .wf-fieldset__wrapper .wf-fieldset__fields-container {
	margin: 0px !important;
	display: flex;
}
.FeedbackFormQuestion .wf-fieldset--error .wf-fieldset__error-message {
	margin-top: -10px;
}

.FeedbackFormEmail {
	margin-top: 30px;
	width: 60%;
	margin: 30px 0px;
}

.FeedbackForm .SystemMessages svg[role='img'] {
	padding-left: 0;
	padding-right: 5px;
	padding-bottom: 20px;
	padding-top: 19px;
}

.FeedbackMicroAppClient {
	border: 2px solid white;
}

.FeedbackBodyContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.ConfirmationPanel {
	display: block;
	width: 545px;
	align-items: flex-start;
}
.ConfirmationPanel p {
	padding: 0px 12px;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	color: #333333;
	line-height: normal;
}
.ConfirmationPanel span {
	font-size: 16px;
}
@media only screen and (max-width: 600px) {
	.ConfirmationPanel {
		width: 95%;
	}
}
.WfEfmyaThumbsIconContainer {
	display: flex;
	justify-content: space-between;
}
.WfEfmyaThumbsIconContainer button {
	background-color: white;
	cursor: pointer;
	margin: 24px;
}
.WfEfmyaThumbsIconContainer img {
	height: 40px;
	width: 40px;
}
